import { Component } from '@angular/core';

@Component({
  selector: 'tig-commons',
  template: `
    <p>
      commons works!
    </p>
  `,
  styles: [
  ]
})
export class CommonsComponent {

}
