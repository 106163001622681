import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ILoginTokenInfo, ITicketInfo } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class EventsHubService {
  public loggedIn$: BehaviorSubject<ILoginTokenInfo | null> = new BehaviorSubject<ILoginTokenInfo | null>(null);
  public httpUnauthorizedError$: Subject<boolean> = new Subject();
  public ticketInfo$: BehaviorSubject<ITicketInfo | null> = new BehaviorSubject<ITicketInfo | null>(null);

  public setLoggedIn(loginTokenInfo: ILoginTokenInfo | null): void {
    this.loggedIn$.next(loginTokenInfo);
  }

  public setUnauthorizedError(value: boolean): void {
    this.httpUnauthorizedError$.next(value);
  }

  public setTicketInfo(value: ITicketInfo): void {
    this.ticketInfo$.next(value);
  }

}
