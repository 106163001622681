import { BaseEntity } from './base-entity.model';
import { Schedule } from './schedule.model';
import { Seat } from './seat.interface';

export class Trip extends BaseEntity {
  public date: string;
  public seats: number;
  public schedule: Schedule;
  public tripSeats: Seat[];
  public uiSelected: boolean;
  public driver?: { id: number };

  constructor(obj: Partial<Trip>) {
    super(obj);
    this.date = obj.date || '';
    this.seats = obj.seats || 0;
    this.schedule = obj.schedule || new Schedule({});
    this.tripSeats = obj.tripSeats || [];
    this.uiSelected = obj.uiSelected || false;
    this.driver = obj.driver || { id: 0 };
  }
}
