import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomHttpEncoder } from 'commons';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  public isLoading$ = new BehaviorSubject<boolean>(false);

  public showSpinner(): void {
    this.isLoading$.next(true);
  }

  public hideSpinner(): void {
    this.isLoading$.next(false);
  }

  public generateParams(params: any = {}): HttpParams {
    let httpParams: HttpParams = new HttpParams({ encoder: new CustomHttpEncoder() });
    Object.keys(params).forEach((key) => {
      const param = params[key];
      if (Array.isArray(param) && param.length > 0) {
        let arrayValue: string = '';
        param.forEach((value, index) => {
          if (index == 0) {
            arrayValue = value;
          }
          else {
            arrayValue += ',' + value;
          }
        });
        httpParams = httpParams.append(key, arrayValue);
      } else {
        httpParams = httpParams.set(key, param);
      }
    });
    return httpParams;
  }

  public generateHeaders(headers: any): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    if (headers) {
      Object.keys(headers).forEach((key) => {
        httpHeaders = httpHeaders.set(key, headers[key]);
      });
    }
    return httpHeaders;
  }

  public generateSeatMatrix(busSeatMatrix: (number | null)[][] | number[]): any[] {
    if (Array.isArray(busSeatMatrix[0])) {
      return (busSeatMatrix as (number | null)[][]).map(row =>
        row.map(value => value !== null ? { seatNumber: value, isOccupied: false, selected: false } : null)
      );
    } else {
      return (busSeatMatrix as number[]).map(value => ({ seatNumber: value, isOccupied: false, selected: false }));
    }
  }

  public roundNearest5(num: number): number {
    return Math.round(num / 5) * 5;
  }

  public getUniqueId(): number {
    return Math.floor(+new Date());
  }

}
