import { BaseEntity } from './base-entity.model';

export class User extends BaseEntity {
  public firstName: string;
  public lastName: string;
  public fullName: string;
  public identification: string;
  public email: string;
  public username: string;
  public reseller?: { id: number };

  constructor(obj: Partial<User>) {
    super(obj);
    this.firstName = obj.firstName || '';
    this.lastName = obj.lastName || '';
    this.fullName = obj.fullName || '';
    this.identification = obj.identification || '';
    this.email = obj.email || '';
    this.username = obj.username || '';
  }
}
