
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractForm } from './form.abstract';

export abstract class AbstractModalForm extends AbstractForm implements OnInit {
  protected constructor(
    formBuilder: FormBuilder,
    protected readonly activeModal: NgbActiveModal) {
    super(formBuilder);
  }

  public get modalTitle(): string {
    return this.isEditMode ? `Edit ${this.entityName}` : `Add ${this.entityName}`;
  }

  public abstract get entityName(): string;

  protected abstract get isEditMode(): boolean;

  public abstract dismiss(): void;
}
