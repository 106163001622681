import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class DateHelperService {
  constructor() {}

  public convertNgbDateToISO(date: NgbDateStruct): string {
    return `${this.padNumber(date.year)}-${this.padNumber(
      date.month
    )}-${this.padNumber(date.day)}`;
  }

  public padNumber(num: number, padSize: number = 2): string {
    let paddedNumber = num.toString();
    while (paddedNumber.length < padSize) {
      paddedNumber = '0' + paddedNumber;
    }
    return paddedNumber;
  }
}
