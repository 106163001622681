import { BaseEntity } from './base-entity.model';

export class Route extends BaseEntity {
  public name: string;
  public departure: string;
  public destiny: string;

  constructor(obj: Partial<Route>) {
    super(obj);
    this.name = obj.name || '';
    this.departure = obj.departure || '';
    this.destiny = obj.destiny || '';
  }
}
