import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { PaginatedList } from '../../models';
import { BASE_CONFIG as CONFIG } from '../../config/base-config';
import { SORT_ORDER } from '../../enums/lib-enums';

@Component({
  template: '',
})
export abstract class AbstractPaginatedList<T> implements OnInit {
  public list: PaginatedList<T> = {
    records: [],
    totalPages: 0,
    numberOfPageRecords: 0,
    totalRecords: 0,
  };
  public paginationInfo = {
    page: CONFIG.pagination.initialPage,
    size: CONFIG.pagination.pageSize,
  };
  public loading: boolean = false;
  public dateTimeFormats = CONFIG.dateTimeFormats;
  public shortDateFormat = CONFIG.dateTimeFormats.shortDate;
  public formMasks = CONFIG.masks;
  public sortOrder: SORT_ORDER = SORT_ORDER.ASC;

  protected constructor(
    protected readonly paginationConfig: NgbPaginationConfig
  ) {
    paginationConfig.boundaryLinks = CONFIG.paginationConfig.boundaryLinks;
    paginationConfig.directionLinks = CONFIG.paginationConfig.directionLinks;
    paginationConfig.maxSize = CONFIG.paginationConfig.maxSize;
    paginationConfig.rotate = CONFIG.paginationConfig.rotate;
    paginationConfig.ellipses = CONFIG.paginationConfig.ellipses;
  }

  public ngOnInit(): void {
    this.loadList();
  }

  public abstract loadList(): void;
  public abstract defaultSorting(): string;
  public abstract onPageSelected(): void;

  public onPageChange(pageNum: number) {
    this.paginationInfo.page = pageNum;
    this.loadList();
    this.onPageSelected();
  }
}
