import { faCalendarDays, faClock } from '@fortawesome/free-regular-svg-icons';
import { faBars, faUser, faTimes, faBell, faQrcode, faCheck, faPenToSquare, faRightFromBracket, faVanShuttle, faEye, faEyeSlash, faLocationDot, faColonSign } from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeIcons = [
  faBars,
  faTimes,
  faUser,
  faBell,
  faQrcode,
  faCheck,
  faPenToSquare,
  faRightFromBracket,
  faVanShuttle,
  faEye,
  faEyeSlash,
  faLocationDot,
  faClock,
  faCalendarDays,
  faColonSign
];

