import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './core/auth-services/authentication.service';
import { EventsHubService } from './core/services/events-hub.service';
import { IAPIError, ILoginCredentials } from './shared/interfaces';
import { CONFIG } from './config';
import { UtilsService } from './core/services/utils.service';

@Component({
  selector: 'online-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private credentials: ILoginCredentials = {
    ...CONFIG.oauth,
    username: 'online.sales',
    password: 'ready2go'
  }
  private errorMsg: string = '';

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly eventsHubService: EventsHubService,
    private readonly utilsService: UtilsService
  ) {
    (window as any).jQuery = (window as any).$;
  }

  public ngOnInit(): void {
    this.login();
  }

  public login(): void {
    this.utilsService.showSpinner();
    this.authenticationService.login(this.credentials).subscribe({
      next: (response) => {
        this.eventsHubService.setLoggedIn(response);
        this.utilsService.hideSpinner();
      },
      error: (error: IAPIError) => {
        this.errorMsg = error.message;
        this.utilsService.hideSpinner();
      }
    });
  }


}



