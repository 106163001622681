export const BASE_CONFIG = {
  currencyDefaultFormat: {
    symbol: '₡',
    decimals: '1.0-0',
  },
  discount: 25.000,
  timeGaps: {
    scanTime: { min: 30 }, // 30 min BEFORE
    alertTime: { min: 150 }, // 150 min AFTER
    sellTime: { min: 150 }, // 150 min AFTER
  },
  oauth: {
    grant_type: 'password',
    client_id: 'tig-trusted-client',
    client_secret: 'secret',
  },
  dateTimeFormats: {
    shortDate: 'dd/MM/yyyy',
    fullDate: 'fullDate',
    dateTime: 'dd/MM/yyyy hh:mm a',
    isoDate: 'YYYY-MM-DD',
  },
  datePickerConfig: {
    today: () => {
      const today = new Date();
      return {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate(),
      };
    },
  },
  httpTimeout: 60000, // time in milliseconds = 1 min
  forms: {
    defaultTextMaxLength: 255,
    passwordMinLength: 8,
    textAreaFullMaxLength: 1000,
  },
  patterns: {
    email:
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    zipCode: /^\d{5}(?:\-?\d{4})?$/,
    onlyNumbres: /^[0-9]+$/,
  },
  masks: {
    phoneNumbers: '(000) 000-0000',
    numbersTwoDecimals: 'separator.2',
    zipCode: '99999-9999',
    thousandSeparator: ',',
    alphanumeric: 'A-Z',
    date: '00/00/0000',
  },
  placeholders: {
    date: 'MM/DD/YYYY',
  },

  paginationConfig: {
    boundaryLinks: true,
    directionLinks: true,
    rotate: true,
    maxSize: 5,
    ellipses: false,
  },
  pagination: {
    pageSize: 25,
    initialPage: 1,
  },
  i18nPath: 'assets/i18n/',
};
