import { BaseEntity } from './base-entity.model';

export class BusType extends BaseEntity {
  public name: string;

  constructor(obj: Partial<BusType>) {
    super(obj);
    this.name = obj.name || '';
  }
}
