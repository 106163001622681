<header class="container-fluid bg-white py-2">
  <div class="row max-content-width justify-content-center">
    <div class="text-center col-md-2 text-md-left">
      <a routerLink="['/']" class="d-inline-block">
        <img
          src="/assets/images/tig-logo.png"
          alt="Transporte Inteligente de Guanacaste"
          class="img-fluid"
        />
      </a>
    </div>
    <div class="col-md-6 d-flex align-items-center">
      <h1>Bienvenido a Transporte Inteligente de Guanacaste</h1>
    </div>
  </div>
</header>
