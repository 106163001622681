export class BaseEntity {
  public id?: number;
  public createdBy?: string;
  public created?: string | Date;
  public updatedBy?: string;
  public updated?: string | Date;

  constructor(obj: Partial<BaseEntity>) {
    this.id = obj.id || 0;
    this.createdBy = obj.createdBy || '';
    this.created = obj.created || '';
    this.updatedBy = obj.updatedBy || '';
    this.updated = obj.updated || '';
  }
}
