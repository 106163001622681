import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbDatepickerI18n, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LaddaModule } from 'angular2-ladda';
import { CommonsModule } from 'commons';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fontAwesomeIcons } from './constants/font-awesome-icons';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NgxPrintModule } from 'ngx-print';
import { CustomDatepickerI18n } from './class/custom-datepicker-i18n';


@NgModule({
  declarations: [
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    CommonsModule,
    NgbDatepickerModule,
    TranslateModule,
    FontAwesomeModule,
    NgbAccordionModule,
    NgxPrintModule
  ],
  exports: [
    ReactiveFormsModule,
    CommonsModule,
    LaddaModule,
    NgbDatepickerModule,
    TranslateModule,
    FontAwesomeModule,
    NgbAccordionModule,
    NgxPrintModule,

    SpinnerComponent
  ],
  providers: [{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(...fontAwesomeIcons);
  }
}
