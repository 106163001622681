import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, timeout } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseEntity, PaginatedList } from '../../models';
import { BASE_CONFIG as CONFIG } from '../../config/base-config';

export abstract class AbstractCRUD<R extends BaseEntity> {
  protected constructor(protected readonly http: HttpClient) {}

  protected abstract get apiPath(): string;
  protected abstract get resourcePath(): string;

  public getRecords(params?: any): Observable<PaginatedList<R>> {
    const url = `${this.apiPath}${this.resourcePath}`;
    return this.http.get<PaginatedList<R>>(url, { params: params }).pipe(
      timeout(CONFIG.httpTimeout),
      catchError((ex) => throwError(() => ex.error))
    ) as any;
  }

  public getRecord(id: number | string, params?: any): Observable<R> {
    const url = `${this.apiPath}${this.resourcePath}/${id}`;
    return this.http.get<R>(url, { params }).pipe(
      timeout(CONFIG.httpTimeout),
      catchError((ex) => throwError(() => ex.error))
    ) as any;
  }

  public createRecord(record: R): Observable<R> {
    const url = `${this.apiPath}${this.resourcePath}`;
    return this.http.post<R>(url, record).pipe(
      timeout(CONFIG.httpTimeout),
      catchError((ex) => throwError(() => ex.error))
    ) as any;
  }

  public updateRecord(record: R): Observable<R> {
    const url = `${this.apiPath}${this.resourcePath}/${record.id}`;
    return this.http.put<R>(url, record).pipe(
      timeout(CONFIG.httpTimeout),
      catchError((ex) => throwError(() => ex.error))
    ) as any;
  }

  public patchRecord(id: number, partialRecord: object): Observable<R> {
    const url = `${this.apiPath}${this.resourcePath}/${id}`;
    return this.http.patch<R>(url, partialRecord).pipe(
      timeout(CONFIG.httpTimeout),
      catchError((ex) => throwError(() => ex.error))
    ) as any;
  }

  public deleteRecord(record: R): Observable<boolean> {
    const url = `${this.apiPath}${this.resourcePath}/${record.id}`;
    return this.http.delete(url).pipe(
      map(() => true),
      catchError((ex) => throwError(() => ex.error))
    ) as any;
  }
}
