import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seatNumber',
})
export class SeatNumber implements PipeTransform {
  private seatNumberForBusType: number = 85;
  private wheelchairSingleBus: number = 68;
  private wheelchairDoubleBus: number = 66;
  private window: string = ' Ventana';
  private aisle: string = ' Pasillo';
  private standing: string = 'De pie ';
  private stand: string = 'DP';
  private wheelchair: string = 'Silla de ruedas';
  private wheel: string = 'SR';
  private singleBusWindowSeats: number[] = [1, 4, 5, 8, 9, 12, 13, 16, 17, 20, 21, 24, 25, 28, 29, 32, 33, 36, 37, 40, 41, 44, 45, 48, 49, 52, 53, 56];
  private doubleBusWindowSeats: number[] = [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 37, 35, 35, 37, 39, 41, 43, 45, 47, 49, 51, 53, 55, 57, 59, 62, 64, 68, 70, 72, 74, 76, 78, 80, 82, 85];

  public transform(seatNumber: number, totalSeats: number = 68, showWindowOrAisle: boolean, showShortSeatName: boolean): string {
    const isSingleBus: boolean = totalSeats < this.seatNumberForBusType;
    const wheelchairSeat: number = isSingleBus ? this.wheelchairSingleBus : this.wheelchairDoubleBus;
    const wheelchairLabel: string = showShortSeatName ? this.wheel : this.wheelchair;
    const standingLabel: string = showShortSeatName ? this.stand : this.standing;
    const maxSeated: number = totalSeats - (isSingleBus ? 11 : 10);
    const labelSeatNumber = this.getLabelSeatNumber(seatNumber, showWindowOrAisle, isSingleBus, wheelchairSeat, wheelchairLabel, standingLabel, maxSeated);
    return labelSeatNumber;
  }

  private getLabelSeatNumber(seatNumber: number, showWindowOrAisle: boolean, isSingleBus: boolean, wheelchairSeat: number, wheelchairLabel: string, standingLabel: string, maxSeated: number): string {
    if (seatNumber === wheelchairSeat) {
      return wheelchairLabel;
    }
    if (seatNumber > maxSeated) {
      return `${standingLabel}${seatNumber - maxSeated}`;
    }
    if (!showWindowOrAisle) {
      return seatNumber.toString();
    }
    const isWindowSeat = isSingleBus ? this.singleBusWindowSeats.includes(seatNumber) : this.doubleBusWindowSeats.includes(seatNumber);
    return `${seatNumber} ${isWindowSeat ? this.window : this.aisle}`;
  }
}
