import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapToAmpm',
})
export class MapToAmpmPipe implements PipeTransform {
  transform(time: string | undefined): string {
    let uiTime = time;
    if (time) {
      const hours = time.split(':')[0];
      const minutes = time.split(':')[1];
      let uiHours;
      let amPm = 'AM';

      switch (hours) {
        case '00':
          uiHours = '12';
          amPm = 'AM';
          break;
        case '12':
          uiHours = '12';
          amPm = 'PM';
          break;
        case '13':
          uiHours = '01';
          amPm = 'PM';
          break;
        case '14':
          uiHours = '02';
          amPm = 'PM';
          break;
        case '15':
          uiHours = '03';
          amPm = 'PM';
          break;
        case '16':
          uiHours = '04';
          amPm = 'PM';
          break;
        case '17':
          uiHours = '05';
          amPm = 'PM';
          break;
        case '18':
          uiHours = '06';
          amPm = 'PM';
          break;
        case '19':
          uiHours = '07';
          amPm = 'PM';
          break;
        case '20':
          uiHours = '08';
          amPm = 'PM';
          break;
        case '21':
          uiHours = '09';
          amPm = 'PM';
          break;
        case '22':
          uiHours = '10';
          amPm = 'PM';
          break;
        case '23':
          uiHours = '11';
          amPm = 'PM';
          break;
        case '24':
          uiHours = '12';
          amPm = 'PM';
          break;

        default:
          uiHours = hours;
      }

      uiTime = uiHours + ':' + minutes + ' ' + amPm;

      return uiTime;
    } else {
      return '';
    }
  }
}
