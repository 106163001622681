import { BaseEntity } from './base-entity.model';
import { IPaymentType } from './payment-type.interface';
import { ITicketSeatRate } from './ticket-seat-rate.interface';

export class TicketPayment extends BaseEntity {
  public source: string;
  public tickets: ITicketSeatRate[];
  public paymentTypes: IPaymentType[];

  constructor(obj: Partial<TicketPayment>) {
    super(obj);
    this.source = obj.source || '';
    this.tickets = obj.tickets || [];
    this.paymentTypes = obj.paymentTypes || [];
  }
}
