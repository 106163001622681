import { NgModule } from '@angular/core';
import { CommonsComponent } from './components/commons.component';
import { MapToAmpmPipe } from './pipes/map-to-ampm/map-to-ampm.pipe';
import { BooleanToHumanPipe } from './pipes/boolean-to-human/boolean-to-human.pipe';
import { SeatNumber } from './pipes/seat-number/seat-number.pipe';

@NgModule({
  declarations: [CommonsComponent, MapToAmpmPipe, BooleanToHumanPipe, SeatNumber],
  imports: [],
  exports: [CommonsComponent, MapToAmpmPipe, BooleanToHumanPipe, SeatNumber],
})
export class CommonsModule {}
