import { BaseEntity } from './base-entity.model';


export class Client extends BaseEntity {
  public firstName: string;
  public lastName: string;
  public fullName: string;
  public identification: string;

  constructor(obj: Partial<Client>) {
    super(obj);
    this.firstName = obj.firstName || '';
    this.lastName = obj.lastName || '';
    this.fullName = obj.fullName || '';
    this.identification = obj.identification || '';
  }
}
