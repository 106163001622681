import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { BASE_CONFIG as CONFIG } from '../../config/base-config';

@Component({
  template: '',
})
export abstract class AbstractForm implements OnInit {
  public form: FormGroup = new FormGroup({});
  public submitAttempt: boolean = false;
  public errorMsg: string = '';
  public formRules = CONFIG.forms;
  public formPatterns = CONFIG.patterns;
  public formPlaceholders = CONFIG.placeholders;
  public formMask = CONFIG.masks;
  public datePickerConfig = CONFIG.datePickerConfig;
  public loading: boolean = false;

  protected constructor(protected readonly formBuilder: FormBuilder) { }

  protected abstract initForm(): void;

  public ngOnInit(): void {
    this.initForm();
  }

  public get isFormInvalid(): boolean {
    return (this.submitAttempt || this.form.touched) && this.form.invalid;
  }

  public isFieldValid(field: string): boolean {
    return this.form.controls[field].enabled ? this.form.controls[field].valid || !this.submitAttempt : true;
  }

  public isGroupFieldValid(field: string, group: string): boolean {
    const formGroup = this.form.controls[group] as FormGroup;
    return formGroup.controls[field].enabled ? formGroup.controls[field].valid || !this.submitAttempt : true;
  }

  public isNestedFieldValid(field: string, arrayName: string, index: number): boolean {
    const formArray = this.form.get(arrayName) as FormArray || [];
    const formGroup = formArray?.controls[index] as FormGroup;
    return formGroup.get(field)?.enabled ? formGroup.get(field)?.valid || !this.submitAttempt : true;
  }
}
