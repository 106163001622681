import { Injectable, OnDestroy } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EventsHubService } from '../services/events-hub.service';
import { ILoginTokenInfo } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor, OnDestroy {

  private subscriptions: Subscription = new Subscription;
  public loginTokenInfo: ILoginTokenInfo | null = null;

  constructor(
    private readonly eventsHubService: EventsHubService,
  ) {
    this.getSubscriptions();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getSubscriptions(): void {
    this.subscriptions.add(
      this.eventsHubService.loggedIn$.subscribe((response: ILoginTokenInfo | null) => {
        this.loginTokenInfo = response;
      }));
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request: HttpRequest<any> = req;
    if (req.url.includes('/api/oauth/token')) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
      request = req.clone({ headers });
    }
    if (this.loginTokenInfo && req.url.includes('/api')) {
      const headers = new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.loginTokenInfo.access_token}`,
        'Content-Type': 'application/json'
      })
      request = req.clone({ headers });
    }
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      this.handleError(error);
      throw error;
    }));
  }

  private handleError(error: HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401 && !error.url?.includes('/token')) {
        this.eventsHubService.setUnauthorizedError(true);
      }
    } else {
      this.eventsHubService.setUnauthorizedError(false);
    }
  }
}
