<online-spinner></online-spinner>
<div id="wrapper">
  <online-header *ngIf="false"></online-header>
  <main id="main-content">
    <div class="background-container">
      <div class="background-image"></div>
      <div class="overlay-image"></div>
    </div>
    <router-outlet></router-outlet>
  </main>
  <online-footer></online-footer>
</div>
